// #CityPowerApp {
//   .MuiDataGrid {
//     &-colCellWrapper {
//       background-color: #fcda90;
//     }

//     &-iconSeparator {
//       opacity: 0;
//     }
//   }
// }

// .MuiDataGrid {
//   &-colCell {
//     display: flex;
//     justify-content: center;
//     border: {
//       color: white;
//       style: solid;
//       width: 3px;
//     }
//   }

//   &-cell {
//     font-size: 14px;
//     color: rgb(100, 100, 100);
//     text-align: left;
//     display: flex;
//     align-items: center;
//     padding: {
//       top: 5px;
//       bottom: 5px;
//     }
//     line-height: normal !important;
//     max-height: none !important;
//     white-space: normal !important;
//     overflow: visible !important;
//     overflow-wrap: anywhere;
//   }

//   &-row {
//     max-height: none !important;
//   }

//   &-overlay {
//     display: flex;
//     align-items: flex-start;
//   }
// }

#CityPowerApp,
#ServestITApp {
  .MuiDataGrid {
    // &-row {
    //   max-height: none !important;
    // }

    &-cell {
      font-size: 14px;
      color: rgb(100, 100, 100);
      text-align: left;
      display: flex;
      align-items: center;
      padding: {
        top: 5px;
        bottom: 5px;
      }
      // line-height: normal !important;
      // max-height: none !important;
      // white-space: normal !important;
      // overflow: visible !important;
      // overflow-wrap: anywhere;
    }

    &-overlay {
      display: flex;
      align-items: flex-start;
    }

    &-colCell {
      display: flex;
      justify-content: center;
      border-color: white;
      border-style: solid;
      border-width: 3px;
    }
  }
}

#CityPowerApp {
  .MuiDataGrid {
    &-colCellWrapper {
      background-color: #fcda90;
    }

    &-iconSeparator {
      opacity: 0;
    }
  }
}
