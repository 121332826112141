@import "variables";

#App {
  margin: 2vw;
}

#AppContainer {
  padding-bottom: 14vh;
}

%fullCover {
  height: 100%;
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#ServestITApp, #ServestISApp, #SSGFacilitiesApp, #BedworthApp {
  %halfCover {
    height: 100%;
    background: no-repeat left center fixed;
    background-size: 0% 100%;
    @media only screen and (min-width: 995px) {
      background-size: 50% 100%;
    }
  }
 
  // %fullfullCover {
  //   height: 150%;
  //   background: no-repeat left center fixed;
  //   background-size: 0% 150%;
  //   @media only screen and (min-width: 995px) {
  //     background-size: 50% 100%;
  //   }
  // }

  &.withBackground {
    &Img {
      @extend %fullCover;
      background: {
        image: url("../img/servest_it/background1.jpg");
        size: 120%;
        repeat: no-repeat;
      }
    }
     &ImgLanding {
       @extend %halfCover;
       overflow-y: auto;
       height: 100%;
       background: {
         image: url("../img/servest_it/pexels-jopwell.jpg");
         repeat: no-repeat;
         size: 50% 100%;
       }
       @media only screen and (max-width: 995px) {
        background-size: cover;
        color: $lightOffGrey;
       }
     }
    // &Color {
    //   @extend %fullfullCover;
    //   background: {
    //     color: #f5f5f5;
    //     size: cover;
    //     repeat: no-repeat;
    //   }
    // }
    // &Color {
    //   @extend %fullCover;
    //   background-color: $lightOffGrey;
    // }
  }

}

// #SiemensApp {
//   @extend %fullCover;
//   background-color: $lightOffGrey;
// }

#SyntellApp {
  &.withBackground {
    &Img {
      @extend %fullCover;
      background: {
        image: url("../img/syntell/background.jpeg");
        size: cover;
        repeat: no-repeat;
      }
    }
  }
  &.withBackgroundHome {
    &Img {
      @extend %fullCover;
      background: {
        image: url("../img/syntell/background.jpeg");
        size: cover;
        repeat: no-repeat;
      }
      position: sticky;
      top: 0;
    }
  }
}