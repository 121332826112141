@import "variables";

#LoginForm {
  .form-text {
    text-align: center;
    font-weight: bold;
    font-size: clamp(10px, 2vw, 18px);
  }

  #loginFormCard {
    border-color: $offGrey;
    border-width: 2px;
  }

  .MuiButton-label {
    font-family: "Gotham", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    text-transform: lowercase;
  }
}

#ServestITApp, #ServestISApp, #SSGFacilitiesApp, #BedworthApp {
  $darkMode: true;

  #LoginForm {
    #loginFormCard {
      background-color: $offGrey;
    }

    @if $darkMode {
      .MuiFormLabel-root,
      #loginFormCard,
      .btn-outline-info,
      #loginFormCard .card-footer a {
        color: white;
      }
      #loginFormCard {
        background-color: rgba(0, 0, 0, 0.6);
      }
      .MuiOutlinedInput-input {
        &,
        &::selection {
          color: white;
        }
      }
      .MuiOutlinedInput-notchedOutline,
      .btn-outline-info {
        border-color: white;
      }
      .MuiInputLabel-outlined {
        background-color: #2b2b2bab;
        border-radius: 10%;
        padding: 3px;
      }
    }
  }
}
