#mapid {
  height: 400px;
}

.map_canvas {
  height: 70vh;
  width: 100%;
  margin-top: 0.6em;
  border-style: solid;
  border-color: silver;
  border-width: 1px;
}
