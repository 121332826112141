#AddressInput {
  .formInputs {
    display: flex;
    justify-content: space-around;
  }

  .formLabels {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  .largeLabel {
    font-size: larger;
  }

  .smallLabel {
    font-size: small;
  }

  #addressAlert {
    color: red;
    text-align: left;
  }

  .tooltip {
    float: left;
  }

  #suburbSearchButton {
    width: 100%;
  }
}
