$CityPowerColor: #f7ae00;
$EnpowerColor: #558367;
$SSGFacilitiesColor: #e76625;
$ServestITColor: #131e98;
$ServestISColor: #131e98;
//$Syntell: #133c98;

$SiemensColor: #009999;
$offGrey: #dbdbdb;
$lightOffGrey: #efefef;

$xs: 0px;
$sm: 600px;
$md: 768px;
$lg: 880px;
$xl: 1200px;
