#CityPowerApp {
  .iconGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
  }

  .iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
    margin-bottom: 4px;
  }

  .icons {
    width: clamp(150px, 0px, 0px) !important;
    height: clamp(150px, 0px, 0px) !important;
    color: white;
    border-radius: 50%;
  }

  .iconCaptions {
    font-size: 14px;
    font-weight: 600;
  }

  .iconRow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .iconGroup p {
    text-align: center;
    color: black;
  }
}
