@import "variables";

/* FORMS >>> */
#formCard {
  border-color: $offGrey;
  border-width: 2px;
}
@media screen and (min-width: $lg) {
  #formBody {
    padding: 2vw 8vw 0 8vw;
  }
}
#finalButtons {
  display: flex;
  margin-bottom: 0;
  justify-content: space-around;
}
.formInputs {
  display: flex;
  justify-content: space-around;
}
.formLabels {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
}
.largeLabel {
  font-size: larger;
}
.smallLabel {
  font-size: small;
}
.MuiSelect-select {
  text-align: left;
}
.formInnerCard {
  padding: 1vw 2vw 0.5vw 2vw;
  margin-bottom: 1vw;
}

#ProfileForm {
  #employeeCol {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiSelect-root {
    text-align: left;
  }
}

#OTPModal {
  .otpInput {
    width: 3rem !important;
    height: 3rem;
    margin: 1rem 1rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .otpContainer {
    display: flex;
    justify-content: center;
  }
}
/* <<< FORMS */
