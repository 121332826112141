
@font-face {
    font-family: Flama;
    src: url(Flama-Regular.ttf);
  }


a, button, span, div, li, input, p {
    font-family: Flama !important;
}
