#LandingPage {

  .image {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  body {
    background-color: #fcbf49;
  }
  .wrapper {
    margin: 10vh;
  }
  //post card styles

  .card {
    border: none;
    padding: 10px;
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    overflow: hidden;
    margin-top: 50px;
    border-radius: 20px;
    min-height: 250px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    @media (max-width: 768px) {
      min-height: 350px;
    }

    @media (max-width: 420px) {
      min-height: 300px;
    }

    &.card-has-bg {
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
      background-size: 120%;
      background-repeat: no-repeat;
      background-position: center center;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        -webkit-filter: grayscale(1);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
      }

      &:hover {
        transform: scale(0.98);
        box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
        background-size: 130%;
        transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

        .card-img-overlay {
          transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
          background: rgba(100, 100, 100, 0.5) ;
          background: linear-gradient(
            0deg,
            rgba(100, 100, 100, 0.5) 0%,
            rgb(104, 104, 104) 100%
          );
        }
      }
    }
    .card-footer {
      background: none;
      border-top: none;
      .media {
        img {
          border: solid 3px rgba(234, 95, 0, 0.3);
        }
      }
    }
    .card-meta {
      color: orange;
    }
    .card-body {
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:hover {
      .card-body {
        margin-top: 30px;
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
      }
      cursor: pointer;
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    .card-img-overlay {
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
     // background: rgb(35, 79, 109);
      // background: linear-gradient(
      //   0deg,
      //   rgba(35, 79, 109, 0.3785889355742297) 0%,
      //   rgba(69, 95, 113, 1) 100%
      // );
      background: rgb(35, 79, 109);
      background: linear-gradient(
        0deg,
        rgba(4, 69, 114, 0.5) 0%,
        rgba(4, 69, 114, 1) 100%
      );
     
    }
  }
}
